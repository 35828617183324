import Cookies from "js-cookie";

export const switchToAB = () => {
  const pubref = localStorage.getItem("pubref");
  if (!pubref) {
    return false;
  }

  const ABPubref = [
    "121365", // Universita di Bologna - Area Biblioteche e Servizi allo Studio
    "86829", // Universitäts- und Stadtbibliothek Köln
    "266563", // Universita di Pisa
    "108344", // Universitäts- und Landesbibliothek Münster
    "158080", // Universita degli Studi di Padova
    "220651", // Scuola Normale Superiore di Pisa
    "88186", // Université Paris 1 Panthéon-Sorbonne
    "accessall123", // De Gruyter Brill internal
  ];

  if (ABPubref.includes(pubref)) {
    if (Cookies.get("AB") == "2025") {
      return false;
    }

    Cookies.set("AB", "2025", {
      expires: 7,
      path: "/",
    });
    return true;
  } else {
    Cookies.remove("AB");
    return false;
  }
};
