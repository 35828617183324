import React, { useEffect } from "react";
import { makeStyles, Paper } from "@material-ui/core";
import SearchButtonMobile from "./SearchButtonMobile";
import { useHistory } from "react-router-dom";
import { getSearchURL } from "../../utils/helper_functions";
import { SEARCH_TYPE } from "../../utils/Constants";
import SearchListItem from "../SearchTab/SearchListItem";

const useStyles = makeStyles((theme) => ({
  searchpaper: {
    // padding: "7px 8px 7px 25px",
    display: "flex",
    alignItems: "center",
    // backgroundColor: "#ffffff",
    // height: "55px",
    // border: "0.5px solid #a4a4a4",
  },
  input: {
    marginLeft: theme.spacing(4),
    "& .MuiInputBase-input::placeholder": {
      opacity: "unset !important",
      color: "#3c3c3c !important",
    },
  },
  field: {
    fontFamily: '"Gotham-Medium"',
    fontSize: "18px",
    lineHeight: "30px",
    marginBottom: "10px",
    color: "#000000",
  },
  bottom: {
    position: "fixed",
    bottom: "0",
    backgroundColor: "#ffffff",
    marginBottom: "20px",
    width: "100%",
    height: "55px",
    padding: "12px 37px",
    left: 0,
    fontFamily: "'Gotham-Medium'!important",
  },
}));
export default function StandardMobile(props) {
  const classes = useStyles();
  const [formdata, setFormdata] = React.useState({
    search: [{ field: "LEMMA", value: "" }],
  });

  useEffect(() => {
    if (props?.values && props.values.search?.length) {
      const s = props.values.search[0];
      let newArr = { ...formdata }; // copying the old data array
      if (s.value) newArr.search[0].value = s.value;
      if (s.field) newArr.search[0].field = s.field;
      setFormdata(newArr);
    }
  }, []);

  const history = useHistory();
  const handleSubmit = (e) => {
    if (e.preventDefault) e.preventDefault();
    const query = getSearchURL(SEARCH_TYPE.STANDARD_SEARCH, formdata);
    history.push({
      pathname: "/results",
      search: query,
    });
  };

  const handleInput = (e, id) => {
    let newArr = { ...formdata }; // copying the old data array

    let value = e.target.value;
    if (["value"].includes(e.target.name)) {
      let name = e.target.name;
      newArr.search[0][name] = value;
    } else if (["field" + id].includes(e.target.name)) {
      let name = "field";
      newArr.search[0][name] = value;
    }
    setFormdata(newArr);

    if (e.autocompleteSelected) {
      handleSubmit(e);
      if (props.close) props.close();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.field}>Lemma</div>
      <Paper square elevation={0} className={classes.searchpaper}>
        <SearchListItem
          mobile={true}
          single={true}
          searchDetails={formdata.search}
          index={0}
          autoFocus={true}
          item={formdata.search[0]}
          handleChange={handleInput.bind(this)}
          close={props.close}
        ></SearchListItem>
        {/* <InputBase
          fullWidth
          className={classes.input}
          name="value"
          value={formdata.search[0].value}
          placeholder={t("Search Term")}
          onChange={(e) => handleInput(e)}
          inputProps={{ "aria-label": "search term", autoCapitalize: "none" }}
        /> */}
      </Paper>
      <div className={props.bottom ? classes.bottom : "mobile-search-btn"}>
        <SearchButtonMobile close={props.close} />
      </div>
    </form>
  );
}
