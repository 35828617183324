import React, { useEffect } from "react";

import OuterSpinner from "../components/Spinner/OuterSpinner";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { getValidateUserAPIData, VALIDATE_USER_API } from "./Constants";
import {
  isJWTPresent,
  onValidateUserSuccessResponse,
} from "./helper_functions";
import { switchToAB } from "./switchToAB";
import { useInitialRender } from "./hooks/useInitialRender";
import Cookies from "js-cookie";

function ValidateUser() {
  const isInitialRender = useInitialRender();

  const history = useHistory();
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);

  const redirectURLStrOrig = urlSearchParams.get("redirect_url");

  const redirectURLStr = redirectURLStrOrig
    ? redirectURLStrOrig
        .replace("logout=success&", "")
        .replace("logout=success", "")
    : undefined;

  const getRedirectUrl = (status) => {
    if (redirectURLStr) {
      return redirectURLStr.includes("%3F") || redirectURLStr.includes("?")
        ? `${redirectURLStr}&login=${status}`
        : `${redirectURLStr}?login=${status}`;
    }
    return null;
  };

  // let reqDataQueryStr = "";
  // if (redirectURLStr !== null && redirectURLStr !== "") {
  //   const redirectURL = new URL(redirectURLStr);
  //   const redirectURLSearchParams = redirectURL.searchParams;

  //   const _llca = redirectURLSearchParams.get("_llca");
  //   const _llch = redirectURLSearchParams.get("_llch");
  //   reqDataQueryStr =
  //     isNotNullOrUndefined(_llca) &&
  //     isNotNullOrUndefined(_llch) &&
  //     _llca !== "" &&
  //     _llch !== ""
  //       ? "?_llca=" + _llca + "&_llch=" + _llch
  //       : "";
  // }

  useEffect(() => {
    const getIP = async () => {
      // let reqData = getValidateUserAPIData();
      // reqData.url = window.location.origin + "/validate" + reqDataQueryStr;

      // console.log(VALIDATE_USER_API, getValidateUserAPIData());

      try {
        const response = await axios.post(
          VALIDATE_USER_API,
          getValidateUserAPIData()
        );
        const data = response.data;

        if (data.login == null) {
          window.location.replace("/");
          return;
        }

        // console.log(JSON.stringify(data, null, 2));
        // console.log("redirectURLStr", redirectURLStr);

        if (data.login === "success") {
          Cookies.remove("AB"); // remove AB testing cookie

          if (!data.jwtToken) {
            window.location.replace("/?login=fail");
            return;
          }

          onValidateUserSuccessResponse(data);

          const shouldSwitchToAB = switchToAB();

          if (redirectURLStr) {
            if (shouldSwitchToAB) {
              window.location.replace(getRedirectUrl("success"));
            } else {
              history.replace(getRedirectUrl("success"), { replace: true });
            }
            return;
          }

          window.location.replace("/?login=success");
          return;
        } else if (data.wayf) {
          // console.log("redirect to: " + response.data.wayf);
          if (!isJWTPresent()) {
            window.location.replace(data.wayf);
            return;
          }
          // a jwtToken exists
          // try redirecting to the redirectURLStr
          if (redirectURLStr) {
            history.replace(getRedirectUrl("success"), { replace: true });
            return;
          }
          // if there's no redirectURLStr, redirect to the home page
          window.location.replace("/?login=success");
          return;
        } else {
          window.location.replace("/about?login=fail");
          return;
        }
      } catch (error) {
        const response = error.response;
        if (!response) {
          console.log(error);
          console.error("failed - response is null");
          window.location.replace("/about?login=fail");
          return;
        } else if (response.status === 401) {
          // UNAUTHORIZED

          // if the user was automatically IP authenticated,
          // redirect to the TLL WAYF page
          if (response.data.auth_method === "ip") {
            // redirect to https://connect.liblynx.com/go/tll
            let wayfURL = "https://connect.liblynx.com/go/tll";
            // add the redirect URL to the query string
            if (redirectURLStr) {
              // add the full path to the redirect URL
              const absoluteRedirectURLStr =
                window.location.origin + redirectURLStr;

              // url encode the redirect URL
              wayfURL +=
                "?target=" + encodeURIComponent(absoluteRedirectURLStr);
            }
            window.location.replace(wayfURL);
            return;
          } else {
            // window.location.replace(response.data.wayf);
            console.log(response);
            console.error("Unauthorized");
            history.replace("/about?login=fail");
            return;
          }
        }

        console.error("Authentication failed");
        console.log(error);
        console.log(response);
        console.log("status: " + response.status);
        history.replace("/about?login=fail");
        return;
      }
    };
    if (isInitialRender) getIP();
  }, []);
  return (
    <div>
      <div className="center-container">
        <h1 className="gm"> Authorizing Your Request </h1>
      </div>
      <OuterSpinner />
    </div>
  );
}

export default ValidateUser;
