import React from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import MobileLogo from "./images/mobilelogodark.png";
import Logo from "./images/DG_dark.svg";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  List,
  Paper,
  InputBase,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import SearchTabs from "./SearchTabs";
import { Trans, useTranslation } from "react-i18next";
import MobileSearchTabs from "./MobileTabs/MobileSearchTabs";
import Toast from "./Toast";
import {
  checkIfLanguageIs,
  isJWTPresent,
  doLogout,
} from "../utils/helper_functions";

import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    display: "flex",
    position: "sticky",
    top: 0,
    zIndex: "999 !important",
    backgroundColor: "#ffffff",
    [theme.breakpoints.up("md")]: {
      // Changing this height also affect the scrollPaddingTop in GlobalStyles. Scrollinto view might hide behind it
      minHeight: "115px",
      borderBottom: "5px solid #005D78",
    },
  },
  appBar: {
    color: "#ffffff",
    backgroundColor: "#ffffff",
    padding: "0 5px",

    [theme.breakpoints.up("md")]: {
      padding: "0 90px",
    },
    "& .logomobile img": {
      height: "15px",
      width: "auto",
    },
  },
  appBarWhite: {
    color: "#000F1B",
    backgroundColor: "#ffffff",
    "& .user-icon svg circle": {
      stroke: "#3c3c3c",
    },
    "& .user-icon svg path": {
      fill: "#3c3c3c",
    },
    "& .user-login-icon svg circle": {
      stroke: "#007596",
      fill: "#007596",
    },
  },
  bgwhite: {
    backgroundColor: "#ffffff!important",
  },
  logo: {
    "& img": {
      width: "32px",
    },
  },
  sectionDesktop: {
    display: "none",

    [theme.breakpoints.up("md")]: {
      width: "100%",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  searchAreaInside: {
    transition:
      "-ms-transform 150ms ease 0s, transform 150ms ease 0s, transform 150ms ease 0s, opacity 50ms ease 20ms, pointer-events 0ms ease 150ms !important",
    pointerEvents: "none !important",
    transformOrigin: " 50% 0% 0px !important",
    top: "0px !important",
    zIndex: "9",
  },
  hiddenSearchInside: {
    position: "absolute",
    top: "0px !important",
    transformOrigin: "50% 0% 0px !important",
    transform: "scale(0.15, 0.727273) translateY(-60px) !important",
    visibility: "hidden",
  },
  sectionMobile: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  navlinks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    alignSelf: "center",
  },
  navbtn: {
    marginLeft: "25px",
    "& button": {
      padding: "0px !important",
    },
  },
  navitem: {
    marginLeft: "30px",
  },
  searchbtn: {
    width: "380px",
    padding: "3px 4px 3px 20px",
    height: "55px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto 0 64px",
    background: "#ffffff",
    border: "0.5px solid #A4A4A4",
  },
  category: {
    paddingRight: "20px",
  },
  input: {
    width: "100%",
  },
  icon: {
    margin: 0,
    padding: 0,
  },
  inside: {
    "& .MuiDrawer-paper": {
      overflowY: "visble!important",
    },

    margin: "0px auto !important",
    maxWidth: "620px !important",
    position: "static",
    pointerEvents: "auto",
  },
  searchtabs: {
    margin: "0px auto !important",
    maxWidth: "620px !important",
    position: "relative",
    pointerEvents: "auto",
  },

  drawer: {
    padding: "0 90px",
    display: "flex",
    justifyContent: "center",
  },
  drawerLogo: {
    position: "fixed",
    top: "0",
    left: "90px",
    "& img": {
      width: "50.5px",
    },
  },
  drawerTabs: {
    padding: "25px",
  },
  drawercont: {
    overflow: "visible!important",
    "& .MuiDrawer-paper": {
      overflowY: "visible!important",
    },
  },
  list: {
    backgroundColor: "#f6f6f8",
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#e1e1e1",
    display: "block",
  },
  whitelinks: {
    paddingBottom: "0!important",
    backgroundColor: "#ffffff",
  },
  menuItemMobile: {
    "& .MuiListItemText-primary": {
      fontFamily: "'Gotham-Medium'!important",
      fontSize: "17px!important",
      lineHeight: "20px",
    },
  },
  menuIconMobile: {
    minWidth: "32px!important",
  },
  menubtn: {
    marginBottom: "20px",
    marginTop: "27px",
    paddingLeft: "24px",
  },
  buttons: {
    marginRight: "10px",
    height: "55px",
    width: "130px",
  },
  closedrawer: {
    position: "fixed",
    right: "0",
    top: "0",
    color: "#ffffff",
  },
  mobileterm: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "35px",
    minHeight: "35px",
  },
  mobileSearchDrawer: {
    width: "100vw",
  },
  closesearch: {
    backgroundColor: "#ffffff",
    position: "sticky",
    top: "0px",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "30px",
    zIndex: "999",
  },
  closesearchbtn: {
    position: "fixed",
    left: "0",
    top: "0",
  },
  profilemenu: {
    marginTop: "70px",
    "&  .MuiPopover-paper": {
      overflow: "visible!important",
    },
    "&  .MuiPopover-paper:before": {
      content: '" "',
      width: "0",
      height: "0",
      position: "absolute",
      borderLeft: "30px solid transparent",
      borderRight: "30px solid transparent",
      borderBottom: "40px solid #fff",
      zIndex: "-2",
      left: "50%",
      transform: "translate(-50%,-50%)",
      marginTop: "15px",
    },
  },
  navitemLanguage: {
    color: "#000000",
  },
  toolbar: {
    minHeight: "70px !important",
  },
  menuListItems: {
    height: "60px",
  },
  logoAndLemma: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    flexDirection: "row",
  },
  lemma: {
    // lineHeight: "40px",
    fontSize: "1.5rem",
    fontFamily: "Merriweather, tllmedium",
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      lineHeight: "50px",
      paddingLeft: "2rem",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));
function NavBarFixed({ search, prefix, values, bglight, lemmaTitle }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const fvalue = ["LEMMA", "FULL_TEXT", "CITATION", "INDEX_LIBRORUM"];
  const fname = ["Lemma", "Full Text", "Citation", "Index Librorum"];
  const getSelectedTextFromValue = (selectedValue) => {
    for (let index = 0; index < fvalue.length; index++) {
      const element = fvalue[index];
      if (element === selectedValue) {
        return fname[index];
      }
    }
    return "";
  };

  if (window.innerWidth <= 768 && bglight) {
    var bgwhite = true;
  }

  var valuedata = values?.search ? values?.search[0]?.value : "";
  const field = getSelectedTextFromValue(
    values?.search ? values?.search[0]?.field ?? "LEMMA" : "LEMMA"
  );
  const [mobileSearch, setMobileSearch] = React.useState(false);
  const toggleMobileSearch = () => {
    setMobileSearch(!mobileSearch);
  };
  const renderMobileDrawer = (
    <Drawer anchor="left" open={mobileSearch} onClose={toggleMobileSearch}>
      <div className={classes.mobileSearchDrawer}>
        <div className={classes.closesearch}>
          <IconButton
            onClick={toggleMobileSearch}
            className={classes.closesearchbtn}
          >
            <svg
              width="43"
              height="43"
              viewBox="0 0 43 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.6055 10.6074L31.8187 31.8206"
                stroke="black"
                strokeWidth="2"
              />
              <path
                d="M31.8203 10.6074L10.6071 31.8206"
                stroke="black"
                strokeWidth="2"
              />
            </svg>
          </IconButton>

          <Typography variant="body1" className="gm">
            Edit your search
          </Typography>
        </div>
        <MobileSearchTabs
          key="nav-mobile-search-tabs"
          id="nav-mobile-search-tabs"
          values={values}
          bottom={true}
          close={toggleMobileSearch}
        />
      </div>
    </Drawer>
  );

  const [snack, setSnack] = React.useState(false);

  const [drawerMenu, setDrawerMenu] = React.useState(false);
  const toggleDrawer = () => {
    setDrawerMenu(!drawerMenu);
  };

  const redirectUrl = encodeURIComponent(
    window.location.pathname + window.location.search
  );
  const validateUrl = `/validate?redirect_url=${redirectUrl}`;

  const renderDrawer = (
    <Drawer anchor="left" open={drawerMenu} onClose={toggleDrawer}>
      <div className={classes.list}>
        <div className={classes.closedrawer}>
          <IconButton onClick={toggleDrawer}>
            <svg
              width="43"
              height="43"
              viewBox="0 0 43 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.6055 10.6094L31.8187 31.8226"
                stroke="white"
                strokeWidth="2"
              />
              <path
                d="M31.8203 10.6094L10.6071 31.8226"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </IconButton>
        </div>

        {localStorage.getItem("jwtToken") === undefined ||
        localStorage.getItem("jwtToken") === null ? (
          <List disablePadding>
            <Link to={validateUrl}>
              <ListItem className={classes.menuListItems}>
                <ListItemIcon className={classes.menuIconMobile}>
                  <svg
                    width="19"
                    height="21"
                    viewBox="0 0 19 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8028 11.6157C14.5935 10.4988 15.8246 8.54426 15.8246 6.25462C15.7686 2.79224 12.9707 0 9.50132 0C5.97597 0 3.17807 2.79224 3.17807 6.31047C3.17807 8.60011 4.40915 10.5547 6.1998 11.6716C2.73041 12.956 0.324219 16.2509 0.324219 20.16H2.00296C2.00296 18.0937 2.84233 16.2509 4.18532 14.9106C5.52831 13.5703 7.43088 12.7326 9.44536 12.7326C11.5158 12.7326 13.3624 13.5703 14.7054 14.9106C16.0484 16.2509 16.8878 18.1496 16.8878 20.16H18.5665C18.6225 16.2509 16.2163 12.956 12.8028 11.6157ZM4.85681 6.31047C4.85681 5.02604 5.36043 3.8533 6.1998 3.01562C7.03917 2.17795 8.21429 1.67535 9.50132 1.67535C10.7884 1.67535 11.9075 2.17795 12.7469 3.01562C13.5863 3.8533 14.0899 5.02604 14.0899 6.25462C14.0899 7.53906 13.5863 8.65595 12.7469 9.49363C11.9075 10.3313 10.7324 10.8339 9.50132 10.8339C8.21429 10.8339 7.09513 10.3313 6.25576 9.49363C5.36043 8.7118 4.85681 7.53906 4.85681 6.31047Z"
                      fill="black"
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary={t("Sign up")}
                  className={classes.menuItemMobile}
                />
              </ListItem>
            </Link>
            <Link to={validateUrl}>
              <ListItem className={classes.menuListItems}>
                <ListItemIcon className={classes.menuIconMobile}>
                  <svg
                    width="19"
                    height="21"
                    viewBox="0 0 19 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8028 11.6157C14.5935 10.4988 15.8246 8.54426 15.8246 6.25462C15.7686 2.79224 12.9707 0 9.50132 0C5.97597 0 3.17807 2.79224 3.17807 6.31047C3.17807 8.60011 4.40915 10.5547 6.1998 11.6716C2.73041 12.956 0.324219 16.2509 0.324219 20.16H2.00296C2.00296 18.0937 2.84233 16.2509 4.18532 14.9106C5.52831 13.5703 7.43088 12.7326 9.44536 12.7326C11.5158 12.7326 13.3624 13.5703 14.7054 14.9106C16.0484 16.2509 16.8878 18.1496 16.8878 20.16H18.5665C18.6225 16.2509 16.2163 12.956 12.8028 11.6157ZM4.85681 6.31047C4.85681 5.02604 5.36043 3.8533 6.1998 3.01562C7.03917 2.17795 8.21429 1.67535 9.50132 1.67535C10.7884 1.67535 11.9075 2.17795 12.7469 3.01562C13.5863 3.8533 14.0899 5.02604 14.0899 6.25462C14.0899 7.53906 13.5863 8.65595 12.7469 9.49363C11.9075 10.3313 10.7324 10.8339 9.50132 10.8339C8.21429 10.8339 7.09513 10.3313 6.25576 9.49363C5.36043 8.7118 4.85681 7.53906 4.85681 6.31047Z"
                      fill="black"
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary={t("Sign in")}
                  className={classes.menuItemMobile}
                />
              </ListItem>
            </Link>
            <Link to={validateUrl}>
              <ListItem className={classes.menuListItems}>
                <ListItemIcon className={classes.menuIconMobile}>
                  <svg
                    width="19"
                    height="21"
                    viewBox="0 0 19 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8028 11.6157C14.5935 10.4988 15.8246 8.54426 15.8246 6.25462C15.7686 2.79224 12.9707 0 9.50132 0C5.97597 0 3.17807 2.79224 3.17807 6.31047C3.17807 8.60011 4.40915 10.5547 6.1998 11.6716C2.73041 12.956 0.324219 16.2509 0.324219 20.16H2.00296C2.00296 18.0937 2.84233 16.2509 4.18532 14.9106C5.52831 13.5703 7.43088 12.7326 9.44536 12.7326C11.5158 12.7326 13.3624 13.5703 14.7054 14.9106C16.0484 16.2509 16.8878 18.1496 16.8878 20.16H18.5665C18.6225 16.2509 16.2163 12.956 12.8028 11.6157ZM4.85681 6.31047C4.85681 5.02604 5.36043 3.8533 6.1998 3.01562C7.03917 2.17795 8.21429 1.67535 9.50132 1.67535C10.7884 1.67535 11.9075 2.17795 12.7469 3.01562C13.5863 3.8533 14.0899 5.02604 14.0899 6.25462C14.0899 7.53906 13.5863 8.65595 12.7469 9.49363C11.9075 10.3313 10.7324 10.8339 9.50132 10.8339C8.21429 10.8339 7.09513 10.3313 6.25576 9.49363C5.36043 8.7118 4.85681 7.53906 4.85681 6.31047Z"
                      fill="black"
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary="Sign in via Institution"
                  className={classes.menuItemMobile}
                />
              </ListItem>
            </Link>
          </List>
        ) : (
          <List disablePadding>
            <ListItem
              onClick={(e) => {
                doLogout();
                handleMenuClose();
                toggleDrawer();
              }}
              style={{ cursor: "pointer" }}
              className={classes.menuListItems}
            >
              <ListItemIcon className={classes.menuIconMobile}>
                <svg
                  width="19"
                  height="21"
                  viewBox="0 0 19 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8028 11.6157C14.5935 10.4988 15.8246 8.54426 15.8246 6.25462C15.7686 2.79224 12.9707 0 9.50132 0C5.97597 0 3.17807 2.79224 3.17807 6.31047C3.17807 8.60011 4.40915 10.5547 6.1998 11.6716C2.73041 12.956 0.324219 16.2509 0.324219 20.16H2.00296C2.00296 18.0937 2.84233 16.2509 4.18532 14.9106C5.52831 13.5703 7.43088 12.7326 9.44536 12.7326C11.5158 12.7326 13.3624 13.5703 14.7054 14.9106C16.0484 16.2509 16.8878 18.1496 16.8878 20.16H18.5665C18.6225 16.2509 16.2163 12.956 12.8028 11.6157ZM4.85681 6.31047C4.85681 5.02604 5.36043 3.8533 6.1998 3.01562C7.03917 2.17795 8.21429 1.67535 9.50132 1.67535C10.7884 1.67535 11.9075 2.17795 12.7469 3.01562C13.5863 3.8533 14.0899 5.02604 14.0899 6.25462C14.0899 7.53906 13.5863 8.65595 12.7469 9.49363C11.9075 10.3313 10.7324 10.8339 9.50132 10.8339C8.21429 10.8339 7.09513 10.3313 6.25576 9.49363C5.36043 8.7118 4.85681 7.53906 4.85681 6.31047Z"
                    fill="black"
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                primary={t("Sign out")}
                className={classes.menuItemMobile}
              />
            </ListItem>
          </List>
        )}
        <List className={classes.whitelinks} disablePadding>
          {localStorage.getItem("user_name") !== undefined &&
          localStorage.getItem("user_name") !== null ? (
            <Link to="#">
              <ListItem
                className={classes.menuListItems}
                style={{
                  borderBottom: "0.5px solid #717173",
                  borderTop: "0.5px solid #717173",
                }}
              >
                <Typography variant="h4" className="gm">
                  {localStorage.getItem("user_name")}
                </Typography>
              </ListItem>
            </Link>
          ) : (
            ""
          )}
          <Link to="/about">
            <ListItem
              className={classes.menuListItems}
              style={{ borderBottom: "0.5px solid #717173" }}
            >
              <Typography variant="h4" className="gm">
                <Trans i18nKey="about">About</Trans>
              </Typography>
            </ListItem>
          </Link>
          <Link to="/help">
            {" "}
            <ListItem
              className={classes.menuListItems}
              style={{ borderBottom: "0.5px solid #717173" }}
            >
              <Typography variant="h4" className="gm">
                <Trans i18nKey="help">Help</Trans>
              </Typography>
            </ListItem>
          </Link>
        </List>
        <div className={classes.menubtn}>
          {/* // disableElevation */}
          <Button
            variant="contained"
            className={classes.buttons}
            color={checkIfLanguageIs("de") ? "primary" : "inherit"}
            onClick={() => handleLanguageChange("de")}
          >
            Deutsch
          </Button>
          {/* // disableElevation */}
          <Button
            variant="contained"
            className={classes.buttons}
            color={
              !checkIfLanguageIs("de") || i18n.language === undefined
                ? "primary"
                : "inherit"
            }
            onClick={() => handleLanguageChange("en")}
          >
            English
          </Button>
        </div>
        <List className={classes.bottomlinks} disablePadding>
          <ListItem className={classes.menuListItems}>
            <Typography
              variant="body1"
              className={classes.menuItemMobile + " gm"}
            >
              <Trans i18nKey="contact">
                <a
                  href={
                    "https://www.degruyter.com/cms/pages/contacts?lang=" +
                    (checkIfLanguageIs("de") ? "de" : "en")
                  }
                  target="_blank"
                >
                  Contact
                </a>
              </Trans>
            </Typography>
          </ListItem>
          <span className={classes.divider}></span>
          <ListItem className={classes.menuListItems}>
            <Typography
              variant="body1"
              className={classes.menuItemMobile + " gm"}
            >
              <Trans i18nKey="privacy">
                <a
                  href={
                    "https://www.degruyter.com/cms/pages/privacy-policy?lang=" +
                    (checkIfLanguageIs("de") ? "de" : "en")
                  }
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </Trans>
            </Typography>
          </ListItem>
          <span className={classes.divider}></span>
          <ListItem className={classes.menuListItems}>
            <Typography
              variant="body1"
              className={classes.menuItemMobile + " gm"}
            >
              <Trans i18nKey="termsCondition">
                <a
                  href={
                    "https://www.degruyter.com/cms/pages/terms-and-conditions?lang=" +
                    (checkIfLanguageIs("de") ? "de" : "en")
                  }
                  target="_blank"
                >
                  Terms &amp; Conditions
                </a>
              </Trans>
            </Typography>
          </ListItem>
          <span className={classes.divider}></span>
          <ListItem className={classes.menuListItems}>
            <Typography
              variant="body1"
              className={classes.menuItemMobile + " gm"}
            >
              <Trans i18nKey="imprint">
                <a
                  href={
                    "https://www.degruyter.com/cms/pages/imprint?lang=" +
                    (checkIfLanguageIs("de") ? "de" : "en")
                  }
                  target="_blank"
                >
                  Imprint
                </a>
              </Trans>
            </Typography>
          </ListItem>
          <span className={classes.divider}></span>
          <ListItem className={classes.menuListItems}>
            <Typography
              variant="body1"
              className={classes.menuItemMobile + " gm"}
            >
              © 2020 De Gruyter
            </Typography>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
  const [searchInside, setSearchInside] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const searchClickHandler = () => {
    setSearchInside(!searchInside);
  };
  const handleLanguageChange = (lang) => {
    ReactGA.event({
      category: "Language",
      action: "Language Change",
      label: lang,
    });
    i18n.changeLanguage(lang);
  };
  const menuId = "primary-search-account-menu";

  const renderMenuItem = () => {
    if (isJWTPresent()) {
      return (
        <Menu
          className={classes.profilemenu}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          TransitionProps={{ timeout: 0 }}
          transitionDuration={0}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose} className="gm fs-16">
            {localStorage.getItem("user_name") !== undefined &&
            localStorage.getItem("user_name") !== null
              ? localStorage.getItem("user_name")
              : ""}
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              doLogout();
              handleMenuClose();
            }}
            className="gm fs-16"
          >
            {" "}
            {t("Sign out")}
          </MenuItem>
        </Menu>
      );
    } else {
      return (
        <Menu
          className={classes.profilemenu}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          TransitionProps={{ timeout: 0 }}
          id={menuId}
          transitionDuration={0}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose} className="gm fs-16">
            <Link to={validateUrl}>
              {t("Sign in")} / {t("Sign up")}
            </Link>
          </MenuItem>
          {/* <MenuItem onClick={handleMenuClose} className="gm fs-16">
            <Link
              to={
                "/start-session?entityID=https%3A%2F%2Fidp.degruyter.com%2Fopenathens&target=" +
                encodeURIComponent(window.location.href)
              }
            >
              {t("Login with WAYFless")}
            </Link>
          </MenuItem> */}
        </Menu>
      );
    }
  };

  let history = useHistory();
  const backToResults = () => {
    history.goBack();
  };
  var headdata = () => {
    if (bgwhite && values?.search && values?.search?.length > 0) {
      return (
        <div className={classes.mobileterm}>
          <IconButton onClick={backToResults}>
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 17L1 9L9 1"
                stroke="black"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
          <Button variant="text" className="gb" onClick={toggleMobileSearch}>
            <span style={{ textTransform: "none", fontSize: "25px" }}>
              {values.search ? values.search[0].value : ""}
            </span>
          </Button>
        </div>
      );
    } else if ((bgwhite && prefix !== null) || prefix !== undefined) {
      return (
        <div className={classes.mobileterm}>
          <IconButton onClick={backToResults}>
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 17L1 9L9 1"
                stroke="black"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
          <Button variant="text" className="times" onClick={toggleMobileSearch}>
            <span style={{ fontSize: "25px" }}>{prefix}</span>
          </Button>
        </div>
      );
    } else {
      return (
        <NavLink className={classes.logo + " logomobile"} to="/">
          <img src={MobileLogo} alt="website logo" />
        </NavLink>
      );
    }
  };
  return (
    <div className={classes.grow}>
      {snack ? (
        <Toast status={true} type="success" message="Logged Out Successfully" />
      ) : (
        ""
      )}
      <AppBar
        elevation={0}
        position="static"
        className={clsx(
          classes.appBar,
          classes.appBarWhite,
          bgwhite ? classes.bgwhite : ""
        )}
      >
        <Toolbar className={classes.toolbar}>
          <div
            className={classes.sectionDesktop}
            style={{ paddingTop: "15px" }}
          >
            <div className={classes.logoAndLemma}>
              <NavLink className={classes.logo + " logo"} to="/">
                <img src={Logo} alt="website logo" />
              </NavLink>

              {lemmaTitle && <div className={classes.lemma}>{lemmaTitle}</div>}
            </div>

            <div className={classes.searchgrow}>
              <div className={classes.sectionDesktop}>
                {searchInside !== true ? (
                  <Paper
                    square
                    elevation={0}
                    className={classes.searchbtn}
                    onClick={searchClickHandler}
                  >
                    {/* <InputBase
                          className={classes.category}
                          value={t(field)}
                          style={{fontFamily:"Gotham-Medium"}}
                          placeholder='Category'
                          inputProps={{ "aria-label": "search category" }}
                      /> */}
                    <InputBase
                      className={classes.input}
                      value={valuedata}
                      placeholder={t("Search Term")}
                      inputProps={{ "aria-label": "search term" }}
                    />
                    <IconButton className={classes.icon}>
                      <svg
                        width="47"
                        height="47"
                        viewBox="0 0 47 47"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="47" height="47" fill="#005D78" />
                        <path
                          d="M28 21.4444L23 27L18 21.4444"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </IconButton>
                  </Paper>
                ) : (
                  ""
                )}
              </div>
              <div className={classes.sectionMobile}></div>
              <div
                className={
                  searchInside
                    ? classes.searchAreaInside
                    : classes.hiddenSearchInside
                }
              >
                <div className={classes.inside}>
                  <Drawer
                    anchor="top"
                    open={searchInside}
                    onClose={searchClickHandler}
                    className={classes.drawercont}
                  >
                    <div className={classes.drawer}>
                      <NavLink className={classes.drawerLogo + " logo"} to="/">
                        <img src={Logo} alt="website logo" />
                      </NavLink>
                      <div className={classes.drawerTabs}>
                        <SearchTabs
                          key="nav-search-tabs"
                          id="nav-search-tabs"
                          values={values}
                          variant="dark"
                          close={searchClickHandler}
                        />
                      </div>
                    </div>
                  </Drawer>
                </div>
              </div>
            </div>
            {!searchInside ? (
              <div className={classes.navlinks}>
                <div className={classes.navitem}>
                  <Link to="/about">
                    <Typography variant="h6" className={classes.title}>
                      <Trans i18nKey="about">About</Trans>
                    </Typography>
                  </Link>
                </div>
                <div className={classes.navitem}>
                  <Link to="/help">
                    <Typography variant="h6" className={classes.title}>
                      <Trans i18nKey="help">Help</Trans>
                    </Typography>
                  </Link>
                </div>
                <div className={classes.navbtn}>
                  {localStorage.getItem("user_name") !== undefined &&
                  localStorage.getItem("user_name") !== null ? (
                    <IconButton
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="primary"
                      className="user-login-icon"
                    >
                      <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="22.5"
                          cy="22.5"
                          r="21.75"
                          stroke="white"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M25.6038 23.9474C27.2689 22.8947 28.4137 21.0526 28.4137 18.8947C28.3617 15.6316 25.7599 13 22.5337 13C19.2555 13 16.6538 15.6316 16.6538 18.9474C16.6538 21.1053 17.7986 22.9474 19.4637 24C16.2375 25.2105 14 28.3158 14 32H15.561C15.561 30.0526 16.3416 28.3158 17.5904 27.0526C18.8393 25.7895 20.6084 25 22.4817 25C24.407 25 26.1241 25.7895 27.373 27.0526C28.6218 28.3158 29.4024 30.1053 29.4024 32H30.9634C31.0154 28.3158 28.7779 25.2105 25.6038 23.9474ZM18.2148 18.9474C18.2148 17.7368 18.6831 16.6316 19.4637 15.8421C20.2442 15.0526 21.3369 14.5789 22.5337 14.5789C23.7305 14.5789 24.7712 15.0526 25.5518 15.8421C26.3323 16.6316 26.8006 17.7368 26.8006 18.8947C26.8006 20.1053 26.3323 21.1579 25.5518 21.9474C24.7712 22.7368 23.6785 23.2105 22.5337 23.2105C21.3369 23.2105 20.2962 22.7368 19.5157 21.9474C18.6831 21.2105 18.2148 20.1053 18.2148 18.9474Z"
                          fill="white"
                        />
                      </svg>
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="primary"
                      className="user-icon"
                    >
                      <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="22.5"
                          cy="22.5"
                          r="21.75"
                          stroke="white"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M25.6038 23.9474C27.2689 22.8947 28.4137 21.0526 28.4137 18.8947C28.3617 15.6316 25.7599 13 22.5337 13C19.2555 13 16.6538 15.6316 16.6538 18.9474C16.6538 21.1053 17.7986 22.9474 19.4637 24C16.2375 25.2105 14 28.3158 14 32H15.561C15.561 30.0526 16.3416 28.3158 17.5904 27.0526C18.8393 25.7895 20.6084 25 22.4817 25C24.407 25 26.1241 25.7895 27.373 27.0526C28.6218 28.3158 29.4024 30.1053 29.4024 32H30.9634C31.0154 28.3158 28.7779 25.2105 25.6038 23.9474ZM18.2148 18.9474C18.2148 17.7368 18.6831 16.6316 19.4637 15.8421C20.2442 15.0526 21.3369 14.5789 22.5337 14.5789C23.7305 14.5789 24.7712 15.0526 25.5518 15.8421C26.3323 16.6316 26.8006 17.7368 26.8006 18.8947C26.8006 20.1053 26.3323 21.1579 25.5518 21.9474C24.7712 22.7368 23.6785 23.2105 22.5337 23.2105C21.3369 23.2105 20.2962 22.7368 19.5157 21.9474C18.6831 21.2105 18.2148 20.1053 18.2148 18.9474Z"
                          fill="white"
                        />
                      </svg>
                    </IconButton>
                  )}
                </div>
                <div className={classes.navbtn}>
                  <Button
                    onClick={() =>
                      handleLanguageChange(
                        checkIfLanguageIs("de") ? "en" : "de"
                      )
                    }
                  >
                    <Typography
                      variant="h6"
                      className={`${classes.title} ${classes.navitemLanguage}`}
                    >
                      {checkIfLanguageIs("de") ? "English" : "Deutsch"}
                    </Typography>
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={classes.sectionMobile}>
            {headdata()}
            {!drawerMenu ? (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
              >
                <svg
                  width="25"
                  height="19"
                  viewBox="0 0 25 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 1H25" stroke="black" strokeWidth="2" />
                  <path d="M0 9.5H25" stroke="black" strokeWidth="2" />
                  <path d="M0 18H25" stroke="black" strokeWidth="2" />
                </svg>
              </IconButton>
            ) : null}
          </div>

          {renderDrawer}
          {renderMobileDrawer}
        </Toolbar>
      </AppBar>
      {renderMenuItem()}
    </div>
  );
}

export default NavBarFixed;
