import { Switch, Route } from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "./components/GlobalStyles";
import theme from "./theme";
import ScrollToTop from "./ScrollToTop";
import Results from "./views/Results";
import Entry from "./views/Entry";
import About from "./views/About";
import Help from "./views/Help";
import IndexEntry from "./views/IndexEntry";
import Parser from "./utils/Parser";
import Homepage from "./views/Homepage";
import ValidateUser from "./utils/ValidateUser";
import Test from "./utils/Test";
import React, { useEffect, useState } from "react";
import Location from "./views/Location";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { v4 as uuidv4 } from "uuid";
import { ReactSession } from "react-client-session";
import RedirectToWayfless from "./utils/RedirectToWayfless";
import {
  isNotNullAndEmptyStr,
  isNotNullOrUndefined,
  onValidateUserSuccessResponse,
} from "./utils/helper_functions";
import { switchToAB } from "./utils/switchToAB";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import * as constants from "./utils/Constants";
import CookieLoader from "./components/CookieLoader";

import ReactGA from "react-ga4";

ReactGA.initialize("G-FL7R0PSJ3E");

// ReactGA.initialize("G-FL7R0PSJ3E", {
//   // useExistingGa: false,
//   alwaysSendToDefaultTracker: true,
//   debug: true,
//   // testMode: true,
// });
// const EntryWrapper = (props) => {
//   return <Entry {...props} wayflessValidation={WayflessValidation} />;
// };

const RoutingFunction = (WayflessValidation) => {
  return (
    <Switch>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/results" component={Results} />
      <Route exact path="/index/rowId" component={Parser} />
      <Route exact path="/article/rowId" component={Parser} />
      <Route exact path="/article/location/:location" component={Location} />
      <Route
        exact
        path="/article/:fileName"
        component={(props) => (
          <Entry {...props} wayflessValidation={WayflessValidation} />
        )}
      />
      <Route exact path="/index/:fileName" component={IndexEntry} />
      <Route exact path="/about" component={About} />
      <Route exact path="/help" component={Help} />
      <Route exact path="/validate" component={ValidateUser} />
      <Route exact path="/start-session" component={RedirectToWayfless} />
      <Route exact path="/test" component={Test} />
    </Switch>
  );
};

function App() {
  useEffect(() => {
    ReactSession.set("sid", uuidv4());
    const aname = localStorage.getItem("aname") ?? "";
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.innerHTML = `var _ll = _ll || [];
                        _ll.push(["setDefaults", {
                          "pid": '${process.env.REACT_APP_LIB_LYNX_PID.trim()}',
                          "sid": '${ReactSession.get("sid")}',
                          "aname": '${aname}',
                          "url": '${window.location.href}',
                          "plid": "TLL",
                          "plname":"TLL Database",
                          "dbid": "TLL",
                          "dbname": "Thesaurus Linguae Latinae"
                        }]);`;

    // load this async script : //connect.liblynx.com/log/js/counter5.min.js
    const counterScript = document.createElement("script");
    counterScript.type = "text/javascript";
    counterScript.async = true;
    counterScript.defer = true;
    counterScript.src = "//connect.liblynx.com/log/js/counter5.min.js";
    document.head.appendChild(counterScript);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(counterScript);
    };
  }, []);

  const [WayflessValidation, setWayflessValidation] = useState("");

  const isValidatePage = window.location.pathname.startsWith("/validate");

  useEffect(() => {
    if (!isValidatePage) {
      const urlSearchParams = new URLSearchParams(window.location.search);

      if (WayflessValidation !== "") {
        console.log("Wayfless validation already tried");
        return;
      }

      const _llca = urlSearchParams.get("_llca");
      const _llch = urlSearchParams.get("_llch");
      const isEZProxy = document.cookie.includes("ezproxy");

      if (
        isEZProxy ||
        (isNotNullAndEmptyStr(_llca) && isNotNullAndEmptyStr(_llch))
      ) {
        trackPromise(
          axios
            .post(
              constants.VALIDATE_USER_API,
              constants.getValidateUserAPIData()
            )
            .then((response) => {
              if (isNotNullOrUndefined(response?.data?.jwtToken)) {
                onValidateUserSuccessResponse(response.data);
                setWayflessValidation("success");
              } else {
                // console.error("wayfless validation failed");
                setWayflessValidation("failed");
              }
            })
            .catch((error) => {
              // console.error("user validation failed", error);
              // Handle the error appropriately here
              setWayflessValidation("failed");
            })
        );
      } else {
        setWayflessValidation("failed");
      }
    }
  }, []);

  useEffect(() => {
    const shouldSwitchToAB = switchToAB();
    if (shouldSwitchToAB) {
      window.location.reload();
    }
  }, []);

  ReactSession.setStoreType("localStorage");
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ScrollToTop />
        <CookieLoader />
        {(WayflessValidation !== "" || isValidatePage) && (
          <RoutingFunction wayflessValidation={WayflessValidation} />
        )}
      </ThemeProvider>
    </Provider>
  );
}

export default App;
