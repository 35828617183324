import { SEARCH_FIELDS, SEARCH_QUALIFIER, SEARCH_TYPE } from "./Constants";
import i18n from "i18next";
import axios from "axios";
export function minimizeXML(data) {
  // const start = performance.now(); // Start the timer

  const result = data
    ?.replace(/\n/g, "")
    ?.replace(/\>[\t ]+\</g, "><")
    ?.replace(/\>[\t ]+$/g, ">")
    ?.replace(/[\n\t ]+/g, " ")
    ?.replaceAll("&amp;nbsp;", "&nbsp;")
    ?.replaceAll("<form", "<formcustom")
    ?.replaceAll("</form>", "</formcustom>");

  // const end = performance.now(); // End the timer
  // console.log(`minimizeXML took ${end - start} milliseconds`);
  return result;
}
export function fetchFileName(name) {
  if (!name) return "";
  var arr = [];
  arr = name.split("/");
  var fileName = arr[arr.length - 1];
  return fileName.substr(0, fileName.length - 4);
}

const otherKeys = ["volume", "paragraph", "line", "page", "prefix"];

export function getSearchURL(searchType, formData, alphabeticField, alphabet) {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("type", searchType);
  if (searchType === SEARCH_TYPE.ALPHABETICAL_SEARCH) {
    urlSearchParams.append(`field`, alphabeticField);
    urlSearchParams.append(`value`, alphabet);
  }
  formData?.search?.forEach((element, index) => {
    if (element?.value && element?.value?.trim()?.length) {
      urlSearchParams.append(`field${index}`, SEARCH_FIELDS[element?.field]);
      urlSearchParams.append(`val${index}`, element?.value);
    }
    if (element?.searchQualifier) {
      urlSearchParams.append(
        `join${index}`,
        SEARCH_QUALIFIER[element?.searchQualifier]
      );
    }
  });
  if (formData) {
    otherKeys.forEach((key) => {
      if (formData[key] !== null && formData[key] !== undefined) {
        urlSearchParams.set(key, formData[key]);
      }
    });
  }
  //Reseting the perfix here
  urlSearchParams.delete("prefix");
  return urlSearchParams.toString();
}

export function getFormDataFromQuery(query) {
  const urlSearchParams = new URLSearchParams(query);
  var formData = {};
  var maxRows = -1;
  urlSearchParams.forEach(function (_, key) {
    if (key.startsWith("field") && key.length > 5) {
      const ind = parseInt(key.substr(5));
      if (ind !== null && ind !== undefined && ind > maxRows) {
        maxRows = ind;
      }
    }
  });
  const searchFields = Object.keys(SEARCH_FIELDS);
  const joins = Object.keys(SEARCH_QUALIFIER);
  var search = new Array(maxRows + 1);
  urlSearchParams.forEach(function (value, key) {
    if (key.startsWith("field") && key.length > 5) {
      const ind = parseInt(key.substr(5));
      if (ind !== null && ind !== undefined && searchFields[value]) {
        if (search[ind]) {
          search[ind].field = searchFields[value].toString();
        } else {
          search[ind] = { field: searchFields[value].toString() };
        }
      }
    } else if (key.startsWith("val")) {
      const ind = parseInt(key.substr(3));
      if (ind !== null && ind !== undefined) {
        if (search[ind]) {
          search[ind].value = value;
        } else {
          search[ind] = { value: value };
        }
      }
    } else if (key.startsWith("join")) {
      const ind = parseInt(key.substr(4));
      if (ind !== null && ind !== undefined) {
        if (search[ind]) {
          search[ind].searchQualifier = joins[value];
        } else {
          search[ind] = { searchQualifier: joins[value] };
        }
      }
    } else if (otherKeys.indexOf(key) !== -1) {
      formData[key] = value;
    }
  });

  formData.search = search;

  return formData;
}

export const getSortBy = (value) => {
  if (value && value.startsWith("pdate")) {
    return "PUBLISH_DATE";
  } else if (value && value.startsWith("lemma")) {
    return "LEMMA";
  }
  return null;
};

export const getSortType = (value) => {
  if (value && value.endsWith("desc")) {
    return false;
  }
  return true;
};

export const getSearchFiltersFromQuery = (query) => {
  const pageNumber = parseInt(query.get("pageNo") ?? "0");
  const prefixFilter = query.get("prefix");
  const searchType = parseInt(query.get("type") ?? "0");
  const size = parseInt(query.get("size") ?? "10");
  const querySortBy = query.get("sortBy");
  const sortBy = getSortBy(querySortBy);
  const sortType = getSortType(querySortBy);
  const searchForm = getFormDataFromQuery(query);
  const alphabetSearch = query.get("value");
  const queryField = parseInt(query.get("field") ?? "0");
  const alphabetSearchField = Object.keys(SEARCH_FIELDS)[queryField];
  const volume = query.get("volume");
  const paragraph = query.get("paragraph");
  const line = query.get("line");
  const page = query.get("page");

  return {
    pageNumber,
    prefixFilter,
    searchType,
    size,
    querySortBy,
    sortBy,
    sortType,
    searchForm,
    alphabetSearch,
    queryField,
    alphabetSearchField,
    volume,
    paragraph,
    line,
    page,
  };
};

export const getSearchURLParams = (searchURL) => {
  const url = new URL(decodeURIComponent(window.location.origin + searchURL));
  const params = new URLSearchParams(url.search);

  return params;
};

const isPharseSearch = (str) => {
  return (
    str?.length &&
    str.length > 2 &&
    str[0] === '"' &&
    str[str.length - 1] === '"'
  );
};

export const prepareSearchFormListForHighlight = (list) => {
  return list
    ?.map((e) => {
      var value = e?.value;
      if (isPharseSearch(value)) {
        value = value.substring(1, value.length - 1);
      } else {
        value = value.split(" ");
      }
      return value;
    })
    ?.flat();
};

export const checkIfLanguageIs = (locale) => {
  return i18n.language?.startsWith(locale);
};

export const isNotNullOrUndefined = (obj) => obj !== null && obj !== undefined;

export const isJWTPresent = () =>
  localStorage.getItem("jwtToken") !== null &&
  localStorage.getItem("jwtToken") !== undefined;

export const onValidateUserSuccessResponse = (data) => {
  if (!data?.jwtToken?.length) {
    return;
  }

  localStorage.setItem("jwtToken", data.jwtToken);
  // console.info(data);

  const counterparty_id = data?.user?.account?.["counterparty_id"];

  const publisher_dot_reference = data?.user?.account?.["publisher.reference"];
  const publisher_reference = data?.user?.account?.["publisher_reference"];
  const pubref = publisher_dot_reference ?? publisher_reference;

  if (isNotNullOrUndefined(pubref)) {
    localStorage.setItem("pubref", pubref);
  }

  if (isNotNullOrUndefined(counterparty_id)) {
    localStorage.setItem("aid", counterparty_id);
  } else if (isNotNullOrUndefined(pubref)) {
    localStorage.setItem("aid", pubref);
  } else {
    localStorage.removeItem("aid");
  }

  localStorage.setItem("TLL_logout_link", data?.user?.logoutLink);

  const account_name =
    data?.user?.account?.["account.name"] ??
    data?.user?.account?.["account_name"];

  if (isNotNullOrUndefined(account_name)) {
    localStorage.setItem("aname", account_name);
  } else {
    localStorage.removeItem("aname");
  }

  let userName = undefined;

  if (
    isNotNullOrUndefined(
      data?.user?.account_individual?.individual?.["display.name"]
    )
  ) {
    userName = data.user.account_individual.individual["display.name"];
  } else if (isNotNullOrUndefined(data?.user?.account?.["account.name"])) {
    userName = data?.user?.account["account.name"];
  } else if (isNotNullOrUndefined(data?.user?.account?.["account_name"])) {
    userName = data?.user?.account["account_name"];
  } else if (
    isNotNullOrUndefined(
      data?.user?.account_individual?.individual?.["display_name"]
    )
  ) {
    userName = data.user.account_individual.individual["display_name"];
  }

  if (isNotNullOrUndefined(userName)) {
    localStorage.setItem("user_name", userName);
  }
};

export const doLogout = async () => {
  const token = localStorage.getItem("jwtToken");
  const logoutLink = localStorage.getItem("TLL_logout_link");
  if (logoutLink) {
    try {
      await fetch(logoutLink, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  localStorage.removeItem("TLL_logout_link");
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("user_name");
  localStorage.removeItem("aname");
  localStorage.removeItem("aid");
  window.location.replace("/?logout=success");
};

export const isNotNullAndEmptyStr = (str) => str !== null && str !== "";

export const fetchWP = async (slug, lang) => {
  if (!lang) return undefined;
  let url = `${process.env.REACT_APP_SERVER_WP}/wp-json/te-wpml/v2/pages/?slug=${slug}`;

  if (lang !== "en") {
    url += `&lang=${lang}`;
  }
  const response = await axios.get(url);
  const content = response.data[0]?.content?.rendered;

  return content;
};

export function processXMLSenseDOM(parsedDom, elementIndex = "0") {
  if (!parsedDom.children) return parsedDom;

  Array.from(parsedDom.children).forEach((child, childIndex) => {
    if (child.tagName.toLowerCase() !== "sense") {
      return processXMLSenseDOM(child, elementIndex + "." + childIndex);
    }

    const senseContent = child.innerHTML;
    const splitIndex = senseContent.indexOf("<cit");

    if (splitIndex === -1) {
      // Process nested <sense> elements if no <cit> tag is found
      return processXMLSenseDOM(child, elementIndex + "." + childIndex);
    }

    const sense_html_part = senseContent.substring(0, splitIndex);
    const content_html_part = senseContent.substring(splitIndex);

    const sense_div = document.createElement("span");
    const senseID = `sense-${elementIndex}.${childIndex}`;
    sense_div.innerHTML = sense_html_part;
    sense_div.id = senseID;
    sense_div.style.cursor = "pointer";

    const div2 = document.createElement("span");
    const citID = `${senseID}-cit`;
    div2.innerHTML = content_html_part;
    div2.id = citID;
    div2.style.visibility = "hidden";

    // child.innerHTML = "";
    const newChild = document.createElement("sense");
    const sense2 = processXMLSenseDOM(
      sense_div,
      elementIndex + "." + childIndex
    );
    const senseContent2 = processXMLSenseDOM(
      div2,
      elementIndex + "." + childIndex + "c"
    );
    newChild.appendChild(sense2);
    newChild.appendChild(senseContent2);

    parsedDom.replaceChild(newChild, child);
    // return child;
  });

  return parsedDom;
}

export function processSenseElements(element, elementIndex = "0") {
  Array.from(element.children).forEach((child, childIndex) => {
    if (child.tagName.toLowerCase() !== "sense") {
      processSenseElements(child, elementIndex + "." + childIndex);
      return;
    }

    let citElementIndex = -1;

    Array.from(child.children).forEach((c, idx) => {
      if (citElementIndex !== -1) return;
      // if (c.tagName.toLowerCase() === "cit") citElementIndex = idx;
      if (c.tagName.toLowerCase() === "note" && c.innerHTML.includes(":")) {
        citElementIndex = idx + 1;
      }
    });

    if (citElementIndex === -1) {
      // Process nested <sense> elements if no <cit> tag is found
      processSenseElements(child, elementIndex + "." + childIndex);
      return;
    }

    const sense_elements = [];
    const content_elements = [];

    Array.from(child.children).forEach((c, i) => {
      if (i < citElementIndex) {
        sense_elements.push(c);
      } else {
        content_elements.push(c);
      }
    });

    const sense_div = document.createElement("span");
    sense_div.append(...sense_elements);
    const div2 = document.createElement("span");
    div2.append(...content_elements);

    const senseID = `sense-${elementIndex}.${childIndex}`;
    const citID = `${senseID}-cit`;
    sense_div.id = senseID;
    sense_div.className = "sense";
    // sense_div.style.cursor = "pointer";
    div2.id = citID;
    div2.className = "sense-cit";
    // div2.style.display = "none";

    child.innerHTML = "";
    // Process nested <sense> elements in each part
    processSenseElements(sense_div, elementIndex + "." + childIndex);
    processSenseElements(div2, elementIndex + "." + childIndex + "c");

    child.appendChild(sense_div);
    child.appendChild(div2);

    // sense_div.addEventListener("click", (event) => {
    //   const citID = event.currentTarget.id + "-cit";
    //   const hiddenDiv = document.getElementById(citID);
    //   if (!hiddenDiv) return;
    //   hiddenDiv.style.display =
    //     hiddenDiv.style.display === "none" ? "unset" : "none";
    // });
  });
}
